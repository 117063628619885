import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    tabela: '',
    filtros: {} as IFiltroValuesArray,
    campos: {} as IFiltroFieldArray,
    colunas: [] as IFiltroField[],
    sidebar: {},
    bancos: [] as IBanco[],
  },
  getters: {
    filtrosSelecionados: (state) => {
      return Object.values(state.filtros);
    },
    colunas: (state) => {
      let colunas = state.colunas as IFiltroField[];
      colunas = colunas.filter((coluna) => {
        return !Object.values(state.campos).some((filtro) => coluna.id === filtro.id);
      });
      return colunas;
    },
  },
  mutations: {
    SET_TABELA(state, payload) {
      state.tabela = payload;
    },
    SET_COLUNAS(state, payload) {
      state.colunas = payload;
      state.campos = {};
      state.filtros = {};
    },
    ADD_FILTRO: (state, payload: IFiltroField) => {
      Vue.set(state.campos, payload.id, payload);
    },
    REMOVE_FILTRO: (state, payload: string) => {
      Vue.delete(state.campos, payload);
    },
    SET_FILTRO_VALUE: (state, payload: IFiltroValues) => {
      Vue.set(state.filtros, payload.field, payload);
    },
    CLEAR_FILTROS: (state) => {
      state.campos = {};
      state.filtros = {};
    },
    SET_SIDEBAR_BADGE: (state, payload) => {
      Vue.set(state.sidebar, payload['id'], payload['value']);
    },
    SET_BANCOS: (state, payload) => {
      Vue.set(state, 'bancos', payload);
    },
  },
});
