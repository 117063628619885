import Vue from 'vue';
import i18n from '@/i18n';

function ComissaoSituacao(value: string): string {
  switch (value) {
    case 'adicionado':
      return i18n.t('comissoes.situacao.adicionado') as string;
    case 'pendente':
      return i18n.t('comissoes.situacao.pendente') as string;
    case 'disponivel':
      return i18n.t('comissoes.situacao.disponivel') as string;
    case 'solicitado':
      return i18n.t('comissoes.situacao.solicitado') as string;
    case 'pago':
      return i18n.t('comissoes.situacao.pago') as string;
    case 'perdido':
      return i18n.t('comissoes.situacao.perdido') as string;
    case 'removido':
      return i18n.t('comissoes.situacao.removido') as string;
    default:
      return '-';
  }
}

function ComissaoTipo(value: string): string {
  switch (value) {
    case 'direta':
      return i18n.t('comissoes.tipo.direta') as string;
    case 'indireta':
      return i18n.t('comissoes.tipo.indireta') as string;
    case 'venda':
      return i18n.t('comissoes.tipo.venda') as string;
    default:
      return '';
  }
}

function ComissaoCor(value: string, prefix = ''): string {
  switch (value) {
    case 'adicionado':
      return `${prefix}danger`;
    case 'pendente':
      return `${prefix}info`;
    case 'disponivel':
      return `${prefix}success`;
    case 'solicitado':
      return `${prefix}warning`;
    default:
    case 'pago':
    case 'perdido':
    case 'removido':
      return `${prefix}light`;
  }
}

function ComissaoBadge(value: string, prefix = ''): string {
  switch (value) {
    case 'adicionado':
      return `${prefix}danger`;
    case 'pendente':
      return `${prefix}info`;
    case 'disponivel':
      return `${prefix}success`;
    case 'solicitado':
      return `${prefix}warning`;
    case 'pago':
      return `${prefix}soft-secondary`;
    case 'perdido':
    case 'removido':
      return `${prefix}soft-danger`;
    default:
      return '';
  }
}

Vue.filter('ComissaoSituacao', ComissaoSituacao);
Vue.filter('ComissaoTipo', ComissaoTipo);
Vue.filter('ComissaoCor', ComissaoCor);
Vue.filter('ComissaoBadge', ComissaoBadge);
