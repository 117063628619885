import { AxiosResponse } from 'axios';
import Api from './Api';

export default class Saque {
  public static async get(request?: IRequestOptions): Promise<GetSaquesResponse> {
    const response = await Api.instance.get<GetSaquesResponse>('/saques', {
      params: request?.params,
    });
    return response.data;
  }

  public static async exportar(request?: IRequestOptions): Promise<AxiosResponse<string>> {
    const response = await Api.instance.get<string>(`/saques`, { params: request?.params });
    return response;
  }

  public static async getByID(id: number): Promise<GetSaqueResponse> {
    const response = await Api.instance.get<GetSaqueResponse>(`/saques/${id}`);
    return response.data;
  }

  public static async getReferralCommission(
    id: number,
    request?: IRequestOptions
  ): Promise<GetReferralCommissionsResponse> {
    const response = await Api.instance.get<GetReferralCommissionsResponse>(`/saques/${id}/referral-commission`, {
      params: request?.params,
    });
    return response.data;
  }

  public static async patch(id: number, data: PatchSaqueRequest): Promise<PatchSaqueResponse> {
    const response = await Api.instance.patch<PatchSaqueResponse>(`/saques/${id}`, data);
    return response.data;
  }

  public static async excluir(id: number): Promise<GenericResponse> {
    const response = await Api.instance.delete<GenericResponse>(`/saques/${id}`);
    return response.data;
  }

  public static async getComprovantes(
    id: number,
    request?: IRequestOptions
  ): Promise<GetComprovantesResponse> {
    const response = await Api.instance.get<GetComprovantesResponse>(`/saques/${id}/comprovantes`, {
      params: request?.params,
    });
    return response.data;
  }

  public static async excluirComprovante(saque: number, id: number): Promise<GenericResponse> {
    const response = await Api.instance.delete<GenericResponse>(`/saques/${saque}/comprovantes/${id}`);
    return response.data;
  }
}
