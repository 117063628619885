





































import { Component, Vue, Prop, PropSync } from 'vue-property-decorator';

@Component
export default class FaturasContext extends Vue {
  @PropSync('value') public readonly currentPage!: number;
  @Prop() public readonly totalRows!: number;
  @Prop() public perPage = 50;

  public pageOptions = [10, 25, 50, 100, 1000];

  updatePage(value: number): void {
    this.$emit('input', value);
  }

  updatePerPage(value: number): void {
    this.$emit('update:perPage', value);
  }
}
