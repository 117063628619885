


















import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class IndicadorCell extends Vue {
  @Prop() public readonly data!: IIndicador;
  @Prop({default: ''}) public readonly tipo!: string;
  @Prop({default: true}) public readonly showId!: boolean;

  get url(): string {
    return `${process.env.VUE_APP_DASHBOARD_URL}/painel/indicador/perfil.php?id=${this.data.id}`;
  }

  get nome(): string {
    return this.data.nome + ' ' + this.data.sobrenome;
  }
}
