



















































import { Component, Vue, Prop, Ref } from 'vue-property-decorator';
import { BPopover } from 'bootstrap-vue';
import { Saque } from '@/plugins/api';

@Component
export default class ExcluirSaque extends Vue {
  @Prop() public readonly saque!: IReferralCommission;
  @Ref() public readonly popover!: BPopover;
  public processing = false;

  get popoverId(): string {
    return `excluir-comissao-${this.saque.id}`;
  }

  get disabled(): boolean {
    if (this.processing) return true;
    return ['pago'].includes(this.saque.status);
  }

  dismiss(): void {
    this.$root.$emit('bv::hide::popover');
  }

  excluir(): void {
    this.dismiss();
    this.processing = true;

    Saque.excluir(this.saque.id)
      .then(() => {
        this.$emit('refresh');
        this.$swal({
          icon: 'success',
          title: 'Saque Apagado',
        });
      })
      .catch((err) => {
        this.$swal({
          icon: 'error',
          title: err.response.data,
        });
      })
      .finally(() => {
        this.processing = false;
      });
  }
}
