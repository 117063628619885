import Vue from 'vue';

function bancoContaTipo(tipo: string): string {
  switch (tipo) {
    case 'cc':
      return 'Corrente';
    case 'cp':
      return 'Poupança';
    default:
      return '';
  }
}

function bancoSituacao(tipo: string): { label: string; icon: string; color: string } {
  switch (tipo) {
    case 'aceito':
      return { label: 'Aceito', icon: 'mdi-credit-card-outline', color: 'success' };
    case 'pendente':
      return { label: 'Pendente', icon: 'mdi-credit-card-clock', color: 'warning' };
    case 'verificado':
      return { label: 'Validado', icon: 'mdi-credit-card', color: 'success' };
    case 'rejeitado':
      return { label: 'Rejeitado', icon: 'mdi-credit-card-off', color: 'danger' };
    default:
      return { label: 'Não Informado', icon: 'mdi-credit-card-off', color: 'secundary' };
  }
}

function bancoSituacaoLabel(tipo: string): string {
  return bancoSituacao(tipo).label;
}
function bancoSituacaoIcon(tipo: string): string {
  return bancoSituacao(tipo).icon;
}
function bancoSituacaoColor(tipo: string, prefix = ''): string {
  return prefix + bancoSituacao(tipo).color;
}

Vue.filter('bancoContaTipo', bancoContaTipo);
Vue.filter('bancoSituacaoLabel', bancoSituacaoLabel);
Vue.filter('bancoSituacaoIcon', bancoSituacaoIcon);
Vue.filter('bancoSituacaoColor', bancoSituacaoColor);
