import Api from './Api';
import { AxiosResponse } from 'axios';

export default class ReferralCommission {
  public static async get(request?: IRequestOptions): Promise<GetReferralCommissionsResponse> {
    const response = await Api.instance.get<GetReferralCommissionsResponse>('/referral-commission', {
      params: request?.params,
    });
    return response.data;
  }

  public static async exportar(request?: IRequestOptions): Promise<AxiosResponse<string>> {
    const response = await Api.instance.get<string>(`/referral-commission`, { params: request?.params });
    return response;
  }

  public static async patch(id: number, data: PatchComissaoRequest): Promise<GenericResponse> {
    const response = await Api.instance.patch<GenericResponse>(`/referral-commission/${id}`, data);
    return response.data;
  }

  public static async recalcular(id: number): Promise<GenericResponse> {
    const response = await Api.instance.post<GenericResponse>(`/referral-commission/${id}/recalcular`);
    return response.data;
  }

  public static async criarSaque(id: number): Promise<PatchSaqueResponse> {
    const response = await Api.instance.post<PatchSaqueResponse>(`/referral-commission/${id}/saques`);
    return response.data;
  }

  public static async getRecebimentos(id: number, request: IRequestOptions): Promise<GetRecebimentosResponse>  {
    const response = await Api.instance.get<GetRecebimentosResponse>(`/referral-commission/${id}/recebimentos`, {
      params: request?.params,
    });
    return response.data;
  }

  public static async postRecebimentos(id: number, data: IRecebimento): Promise<GenericResponse>  {
    const response = await Api.instance.post<GenericResponse>(`/referral-commission/${id}/recebimentos`, data);
    return response.data;
  }

  public static async deleteRecebimentos(comissao: number, id: number): Promise<GenericResponse>  {
    const response = await Api.instance.delete<GenericResponse>(`/referral-commission/${comissao}/recebimentos/${id}`);
    return response.data;
  }
}
