













import { Component, Vue } from 'vue-property-decorator';

@Component
export default class PageTitle extends Vue {
  get title(): string {
    return this.$route.meta.title;
  }

  get nome(): string {
    return this.$route.meta.nome;
  }

  get description(): string {
    return this.$route.meta.description;
  }

  get root(): string {
    return this.$route.meta.root;
  }

  get rootName(): string {
    return this.$route.meta.rootName;
  }
}
