










import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component
export default class FiltroId extends Vue {
  @Prop() public readonly data!: IFiltroField;
  public text = '';

  @Watch('text')
  input(value: string): void {
    const filtro: IFiltroValues = {
      field: this.data.id,
      operator: '=',
      value: value,
    };
    this.$emit('input', filtro);
  }
}
