import i18n from '@/i18n';
import { BvTableField } from 'bootstrap-vue';

const columns: Array<{ key: string } & BvTableField> = [
  {
    key: 'id',
    label: i18n.t('colunas.id') as string,
    sortable: true,
    thStyle: { width: '120px' },
    stickyColumn: true,
  },
  {
    key: 'id_indicacao',
    label: i18n.t('colunas.indicacao') as string,
    sortable: true,
    thStyle: { width: '120px' },
  },
  {
    key: 'usuarios.id',
    label: i18n.t('colunas.codigo.favorecido') as string,
    sortable: true,
    thStyle: { width: '60px' },
    thClass: 'text-right',
    tdClass: 'text-right',
  },
  {
    key: 'usuarios.nome',
    label: i18n.tc('colunas.nome.favorecido', 1) as string,
    sortable: true,
    thStyle: { width: '200px' },
  },
  {
    key: 'vendedores.nome',
    label: i18n.tc('colunas.nome.vendedor', 1) as string,
    sortable: true,
  },
  {
    key: 'simulacoes.nome',
    label: i18n.tc('colunas.nome.negocio', 2) as string,
    sortable: true,
    thStyle: { width: '200px' },
  },
  {
    key: 'indicacoes.valor_adesao',
    label: i18n.t('colunas.valor.adesao') as string,
    sortable: true,
    thStyle: { width: '150px' },
    thClass: 'text-center',
  },
  {
    key: 'valor_entrada',
    label: i18n.tc('colunas.valor.entrada', 2) as string,
    sortable: true,
    thStyle: { width: '150px' },
    thClass: 'text-center',
  },
  {
    key: 'valor_parcela',
    label: i18n.tc('colunas.valor.parcela', 2) as string,
    sortable: true,
    thStyle: { width: '150px' },
    thClass: 'text-center',
  },
  {
    key: 'soma_faturas',
    label: i18n.tc('faturas.colunas.soma', 2) as string,
    sortable: true,
    thStyle: { width: '150px' },
    thClass: 'text-center',
  },
  {
    key: 'perfil_comissao',
    label: i18n.tc('comissoes.colunas.perfil', 2) as string,
    sortable: true,
    thStyle: { width: '150px' },
    thClass: 'text-center',
  },
  {
    key: 'valor',
    label: i18n.t('colunas.valor.comissao') as string,
    sortable: true,
    thStyle: { width: '130px' },
    thClass: 'text-center',
  },
  {
    label: i18n.t('colunas.situacao') as string,
    key: 'situacao',
    sortable: true,
    thStyle: { width: '100px' },
    thClass: 'text-center',
  },
  {
    label: i18n.tc('colunas.data.criacao', 2) as string,
    key: 'data_adicionado',
    sortable: true,
    thStyle: { width: '100px' },
    thClass: 'text-center',
  },
  {
    label: i18n.tc('colunas.data.autorizacao', 2) as string,
    key: 'data_pendente',
    sortable: true,
    thStyle: { width: '100px' },
    thClass: 'text-center',
  },
  {
    label: i18n.tc('colunas.data.disponivel', 2) as string,
    key: 'data_disponivel',
    sortable: true,
    thStyle: { width: '100px' },
    thClass: 'text-center',
  },
  {
    label: i18n.tc('colunas.data.solicitacao', 2) as string,
    key: 'data_solicitacao',
    sortable: true,
    thStyle: { width: '100px' },
    thClass: 'text-center',
  },
  {
    label: i18n.tc('colunas.data.pagamento', 2) as string,
    key: 'data_pagamento',
    sortable: true,
    thStyle: { width: '100px' },
    thClass: 'text-center',
  },
];

const columnsModal: Array<{ key: string } & BvTableField> = [
  {
    label: i18n.t('colunas.id') as string,
    key: 'id',
    sortable: false,
    thClass: 'text-center',
  },
  {
    label: i18n.t('colunas.nome.short') as string,
    key: 'nome',
    sortable: false,
    thClass: 'text-center',
  },
  {
    label: i18n.t('colunas.valor.short') as string,
    key: 'valor',
    sortable: false,
    thClass: 'text-center',
  },
  {
    label: i18n.t('colunas.tipo') as string,
    key: 'tipo',
    sortable: false,
    thStyle: { width: '70px' },
    thClass: 'text-center',
  },
  {
    label: i18n.t('colunas.situacao') as string,
    key: 'situacao',
    sortable: false,
    thStyle: { width: '60px' },
    thClass: 'text-center',
  },
  {
    label: i18n.tc('colunas.data.criacao', 1) as string,
    key: 'data_criacao',
    sortable: false,
    thStyle: { width: '60px' },
    thClass: 'text-center',
  },
  {
    label: i18n.tc('colunas.data.vencimento', 1) as string,
    key: 'data_vencimento',
    sortable: false,
    thStyle: { width: '60px' },
    thClass: 'text-center',
  },
  {
    label: i18n.tc('colunas.data.pagamento', 1) as string,
    key: 'data_pagamento',
    sortable: false,
    thStyle: { width: '60px' },
    thClass: 'text-center',
  },
  {
    label: i18n.t('colunas.url') as string,
    key: 'url',
    sortable: false,
    thStyle: { width: '40px' },
    thClass: 'text-center',
  },
];

const columnsRecebimentos: Array<{ key: string } & BvTableField> = [
  {
    label: i18n.t('comissoes.recebimento.metodo') as string,
    key: 'metodo',
    sortable: false,
    thClass: 'px-3',
    thStyle: { width: `${(2/12)*100}%` },
    tdClass: 'px-3',
  },
  {
    label: i18n.t('comissoes.recebimento.valor') as string,
    key: 'valor',
    sortable: false,
    thClass: 'px-3',
    thStyle: { width: `${(2/12)*100}%` },
    tdClass: 'px-3',
  },
  {
    label: i18n.t('comissoes.recebimento.data_recebimento') as string,
    key: 'data_recebimento',
    sortable: false,
    thClass: 'px-3',
    thStyle: { width: `${(2/12)*100}%` },
    tdClass: 'px-3',
  },
  {
    label: i18n.t('comissoes.recebimento.observacoes') as string,
    key: 'observacoes',
    sortable: false,
    thClass: 'px-3',
    thStyle: { width: `${(5/12)*100}%` },
    tdClass: 'px-3',
  },
  {
    label: '',
    key: 'acoes',
    sortable: false,
    thClass: 'text-right',
    thStyle: { width: `${(1/12)*100}%` },
    tdClass: 'px-3 py-0 text-right',
  },
];

const situacoes: ISelectSituacao[] = [
  {
    text: i18n.t('comissoes.situacao.todos') as string,
    value: '',
    variant: '',
  },
  {
    text: i18n.t('comissoes.situacao.adicionado') as string,
    value: 'adicionado',
    variant: 'danger',
  },
  {
    text: i18n.t('comissoes.situacao.pendente') as string,
    value: 'pendente',
    variant: 'info',
  },
  {
    text: i18n.t('comissoes.situacao.disponivel') as string,
    value: 'disponivel',
    variant: 'success',
  },
  {
    text: i18n.t('comissoes.situacao.solicitado') as string,
    value: 'solicitado',
    variant: 'warning',
  },
  {
    text: i18n.t('comissoes.situacao.pago') as string,
    value: 'pago',
    variant: '',
  },
  {
    text: i18n.t('comissoes.situacao.perdido') as string,
    value: 'perdido',
    variant: '',
  },
  {
    text: i18n.t('comissoes.situacao.removido') as string,
    value: 'removido',
    variant: '',
  },
];

const columnsFilter: Array<IFiltroField> = [
  {
    id: 'id',
    label: i18n.t('colunas.id') as string,
    type: 'id',
  },
  {
    id: 'id_indicacao',
    label: i18n.t('colunas.indicacao') as string,
    type: 'id',
  },
  {
    id: 'usuarios.id',
    label: i18n.t('colunas.codigo.favorecido') as string,
    type: 'id',
  },
  {
    id: 'usuarios.nome',
    label: i18n.tc('colunas.nome.favorecido', 1) as string,
    type: 'text',
  },
  {
    id: 'comissoes.tipo',
    label: i18n.t('comissoes.tipo.label') as string,
    type: 'enum',
    enum: [
      { value: 'direta', label: i18n.t('comissoes.tipo.direta') as string },
      { value: 'indireta', label: i18n.t('comissoes.tipo.indireta') as string },
      { value: 'venda', label: i18n.t('comissoes.tipo.venda') as string },
    ],
  },
  {
    id: 'comissoes.valor',
    label: i18n.t('colunas.valor.comissao') as string,
    type: 'number',
  },
  {
    id: 'vendedores.nome',
    label: i18n.tc('colunas.nome.vendedor', 2) as string,
    type: 'text',
  },
  {
    id: 'simulacoes.nome',
    label: i18n.tc('colunas.nome.negocio', 2) as string,
    type: 'text',
  },
  {
    id: 'simulacoes.valor_fipe',
    label: i18n.t('colunas.valor.protecao') as string,
    type: 'number',
  },
  {
    id: 'comissoes.situacao',
    label: i18n.t('colunas.situacao') as string,
    type: 'enum',
    enum: situacoes.map<IFiltroEnum>((item) => {
      return { value: item.value, label: item.text };
    }),
  },
  {
    id: 'comissoes.data_adicionado',
    label: i18n.tc('colunas.data.criacao', 2) as string,
    type: 'date',
  },
  {
    id: 'comissoes.data_pendente',
    label: i18n.tc('colunas.data.autorizacao', 2) as string,
    type: 'date',
  },
  {
    id: 'comissoes.data_disponivel',
    label: i18n.tc('colunas.data.disponivel', 2) as string,
    type: 'date',
  },
  {
    id: 'comissoes.data_solicitacao',
    label: i18n.tc('colunas.data.solicitacao', 2) as string,
    type: 'date',
  },
  {
    id: 'comissoes.data_pagamento',
    label: i18n.tc('colunas.data.pagamento', 2) as string,
    type: 'date',
  },
];

export { columns, columnsFilter, columnsModal, columnsRecebimentos, situacoes };
