import Api from './Api';
import { AxiosResponse } from 'axios';

export default class Comissoes {
  public static async get(request?: IRequestOptions): Promise<GetComissoesResponse> {
    const response = await Api.instance.get<GetComissoesResponse>('/comissoes', {
      params: request?.params,
    });
    return response.data;
  }

  public static async exportar(request?: IRequestOptions): Promise<AxiosResponse<string>> {
    const response = await Api.instance.get<string>(`/comissoes`, { params: request?.params });
    return response;
  }

  public static async patch(id: number, data: PatchComissaoRequest): Promise<GenericResponse> {
    const response = await Api.instance.patch<GenericResponse>(`/comissoes/${id}`, data);
    return response.data;
  }

  public static async recalcularComissao(id: number): Promise<GenericResponse> {
    const response = await Api.instance.post<GenericResponse>(`/comissoes/${id}/recalcular`);
    return response.data;
  }

  public static async criarSaque(id: number): Promise<PatchSaqueResponse> {
    const response = await Api.instance.post<PatchSaqueResponse>(`/comissoes/${id}/saques`);
    return response.data;
  }

  public static async getRecebimentos(id: number, request: IRequestOptions): Promise<GetRecebimentosResponse>  {
    const response = await Api.instance.get<GetRecebimentosResponse>(`/comissoes/${id}/recebimentos`, {
      params: request?.params,
    });
    return response.data;
  }

  public static async postRecebimentos(id: number, data: IRecebimento): Promise<GenericResponse>  {
    const response = await Api.instance.post<GenericResponse>(`/comissoes/${id}/recebimentos`, data);
    return response.data;
  }

  public static async deleteRecebimentos(comissao: number, id: number): Promise<GenericResponse>  {
    const response = await Api.instance.delete<GenericResponse>(`/comissoes/${comissao}/recebimentos/${id}`);
    return response.data;
  }
}
