import Vue from 'vue';
import i18n from '@/i18n';

function FaturaSituacao(value: string): string {
    switch (value) {
        case 'canceled':
            return i18n.t('faturas.situacoes.cancelado') as string;
        case 'refunded':
            return i18n.t('faturas.situacoes.reembolsado') as string;
        case 'expired':
            return i18n.t('faturas.situacoes.vencido') as string;
        case 'in_protest':
            return i18n.t('faturas.situacoes.protesto') as string;
        case 'chargeback':
            return i18n.t('faturas.situacoes.estorno') as string;
        case 'partially_paid':
            return i18n.t('faturas.situacoes.parcial') as string;
        case 'authorized':
            return i18n.t('faturas.situacoes.autorizado') as string;
        case 'paid':
            return i18n.t('faturas.situacoes.pago') as string;
        case 'pending':
            return i18n.t('faturas.situacoes.pendente') as string;
        default:
        case 'empty':
            return i18n.t('faturas.situacoes.indisponivel') as string;
    }
}

function FaturaCor(value: string, prefix = ''): string {
    switch (value) {
        case 'refunded':
        case 'expired':
        case 'in_protest':
        case 'chargeback':
            return `${prefix}danger`;
        case 'partially_paid':
        case 'canceled':
            return `${prefix}warning`;
        case 'authorized':
        case 'paid':
            return `${prefix}success`;
        default:
        case 'pending':
        case 'empty':
            return `light`;
    }
}

function FaturaBadge(value: string, prefix = ''): string {
    switch (value) {
        case 'refunded':
        case 'expired':
        case 'in_protest':
        case 'chargeback':
            return `${prefix}danger`;
        case 'partially_paid':
        case 'canceled':
            return `${prefix}warning`;
        case 'authorized':
        case 'paid':
            return `${prefix}success`;
        default:
        case 'pending':
        case 'empty':
            return `${prefix}soft-secondary`;
    }
}

function FaturaTipo(value: string): string {
    switch (value) {
        case 'pix':
            return 'PIX';
        case 'cartao':
            return 'Cartão';
        case 'boleto1':
            return 'Boleto Entrada';
        case 'boleto2':
            return 'Boleto Parcela';
        default:
            return 'Boleto';
    }
}

function FaturaPlano(value: string): string {
    switch (value) {
        case 'vendedor_apvs':
            return 'Vendedor CRM';
        case 'clube_somar_prata':
            return 'Clube de Desconto Combustível';
        default:
            return 'Não definido';
    }
}

function FaturaCupom(value: string): string {
    if (value.length > 0) {
        return value;
    }
    return 'Sem cupom'
}

Vue.filter('FaturaSituacao', FaturaSituacao);
Vue.filter('FaturaCor', FaturaCor);
Vue.filter('FaturaBadge', FaturaBadge);
Vue.filter('FaturaTipo', FaturaTipo);
Vue.filter('FaturaPlano', FaturaPlano);
Vue.filter('FaturaCupom', FaturaCupom);
