import Api from './Api';

export default class Usuario {
  public static async get(request?: IRequestOptions): Promise<GetUsuariosResponse> {
    const response = await Api.instance.get<GetUsuariosResponse>('/usuarios', {
      params: request?.params,
    });
    return response.data;
  }

  public static async getByID(id: number): Promise<GetUsuarioResponse> {
    const response = await Api.instance.get<GetUsuarioResponse>(`/usuarios/${id}`);
    return response.data;
  }

  public static async store(request?: IRequestOptions): Promise<GenericResponse> {
    const response = await Api.instance.post<GenericResponse>(`/usuarios/store`,{
      params: request?.params,
    });
    return response.data;
  }
  public static async delete(id: number): Promise<GenericResponse> {
    const response = await Api.instance.delete<GenericResponse>(`/usuarios/${id}`);
    return response.data;
  }

  public static async getProdutos(id: number): Promise<GetFaturasResponse> {
    const response = await Api.instance.get<GetFaturasResponse>(`/usuarios/produtos/${id}`);
    return response.data;
  }
}
