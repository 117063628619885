import { AxiosResponse } from 'axios';
import Api from './Api';

export default class Faturas {
  public static async get(request?: IRequestOptions): Promise<GetFaturasResponse> {
    const response = await Api.instance.get<GetFaturasResponse>(`/faturas`, {
      params: request?.params,
    });
    return response.data;
  }

  public static async getByID(id: number): Promise<GetFaturaResponse> {
    const response = await Api.instance.get<GetFaturaResponse>(`/faturas/${id}`);
    return response.data;
  }

  public static async exportar(request?: IRequestOptions): Promise<AxiosResponse<string>> {
    const response = await Api.instance.get<string>(`/faturas`, { params: request?.params });
    return response;
  }
}
