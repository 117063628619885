import { Api } from '.';

export default class SolicitacaoBanco {
  public static async get(request?: IRequestOptions): Promise<GetSolicitacoesResponse> {
    const response = await Api.instance.get<GetSolicitacoesResponse>('/solicitacoes', {
      params: request?.params,
    });
    return response.data;
  }

  public static async put(id: number, data: PatchSaqueRequest): Promise<GenericResponse> {
    const response = await Api.instance.put<GenericResponse>(`/solicitacoes/${id}`, data);
    return response.data;
  }
}
