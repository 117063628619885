

































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component
export default class FiltroNumber extends Vue {
  @Prop() public readonly data!: IFiltroField;
  public operator = 'eq';
  public contem = true;
  public valorMenor = '';
  public valorMaior = '';

  get radioID(): string {
    return `contem-${this.data.id}`;
  }

  get placeholder(): string {
    return this.operator === 'rng' ? 'Valor Menor' : 'Valor';
  }

  get value(): number | number[] {
    return this.operator === 'rng'
      ? [
          this.lazyFormatter(this.valorMenor),
          this.lazyFormatter(this.valorMaior),
        ]
      : this.lazyFormatter(this.valorMenor);
  }

  @Watch('value')
  @Watch('operator')
  input(): void {
    const filtro: IFiltroValues = {
      field: this.data.id,
      operator: this.operator,
      value: this.value,
    };
    this.$emit('input', filtro);
  }

  formatter(value: string): string {
    let valor: string | number = value.replace(/[^\d.,]/g, '');
    valor = valor.split('.').join('');
    const partes = valor.split(',');

    let inteiro: string | number = partes[0];
    inteiro = Intl.NumberFormat('pt-BR', {
      style: 'decimal',
      maximumFractionDigits: 20,
    }).format(parseFloat(inteiro));

    const decimal = partes.length > 1 ? `,${partes[1]}` : '';

    return `${inteiro}${decimal}`;
  }

  lazyFormatter(value: string): number {
    let valor: string | number = value.replace(/[^\d.,]/g, '');
    valor = valor.split('.').join('');
    valor = valor.split(',').join('.');
    if (valor.length === 0) {
      return 0;
    }
    return parseFloat(valor);
  }
}
