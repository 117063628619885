









import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class ContextIndicador extends Vue {
  @Prop() public readonly indicador!: string;

  public urlParceiros = process.env.VUE_APP_DASHBOARD_URL + '/painel/indicador/perfil.php?id=';
}
