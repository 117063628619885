





















import { Component, Vue } from 'vue-property-decorator';
import PageTitle from '@/layouts/components/PageTitle.vue';
import PageFooter from '@/layouts/components/PageFooter.vue';
import Sidebar from '@/layouts/components/sidebar/Sidebar.vue';
import Topbar from '@/layouts/components/topbar/Topbar.vue';
import FiltrosSidebar from '@/components/Filtros/Sidebar.vue';
import { Auth, SolicitacaoBanco, Bancos } from '@/plugins/api';

@Component({
  components: {
    PageFooter,
    PageTitle,
    Sidebar,
    Topbar,
    FiltrosSidebar,
  },
})
export default class Main extends Vue {
  public interval: NodeJS.Timeout | null = null;
  public opts = {
    vuescroll: {},
    scrollPanel: {
      scrollingX: true,
    },
    rail: {
      gutterOfSide: '12px',
      gutterOfEnds: '72px',
    },
    bar: {
      keepShow: true,
      background: '#c1c1c1',
      size: '8px',
      specifyBorderRadius: false,
    },
  };

  created() {
    this.$router.beforeEach((to, from, next) => {
      this.$store.commit('CLEAR_FILTROS');
      next();
    });

    Bancos.get()
      .then((result: GetBancosResponse) => {
        this.$store.commit('SET_BANCOS', result.data ?? []);
      })
      .catch(() => {
        this.$store.commit('SET_BANCOS', []);
      });
  }

  mounted(): void {
    this.updateNotification();
  }

  updateNotification(): void {
    if (Auth.instance.isLogged) {
      const request = {
        params: {
          size: '0',
          filters: [
            {
              field: 'dados_bancarios_solicitacao.situacao',
              operator: 'eq',
              value: 'pendente',
            },
          ],
        },
      };
      SolicitacaoBanco.get(request).then(
        (response: GetSolicitacoesResponse) => {
          if (
            this.$store.state.sidebar['/dados-bancarios'] !==
            response.metadata.total
          ) {
            this.$store.commit('SET_SIDEBAR_BADGE', {
              id: '/dados-bancarios',
              value: response.metadata.total,
            });
          }
        }
      );
    }
  }
}
