













import {Component, Vue} from 'vue-property-decorator';
import SidebarMenu from './SidebarMenu.vue';
import {dashboardRoutes} from '@/router/index';
import {RouteConfig} from 'vue-router/types/router';

@Component({
  components: {
    SidebarMenu,
  },
})
export default class Sidebar extends Vue {
  public menu: ISidebarMenuItem[] = [];

  created() {
    dashboardRoutes.forEach((route: RouteConfig) => {
      if (route.meta.sidebar) {
        const item: ISidebarMenuItem = {
          title: route.meta.title,
          href: route.path,
          icon: route.meta.sidebar.icon,
        };
        this.menu.push(item);
      }
    });
  }
}
