import Vue from 'vue';
import i18n from '@/i18n';

function BancoSituacao(value: string) {
  switch (value) {
    case 'rejeitado':
      return i18n.t('indicador.banco.situacoes.rejeitado');
    case 'pendente':
      return i18n.t('indicador.banco.situacoes.pendente');
    case 'verificado':
      return i18n.t('indicador.banco.situacoes.verificado');
    default:
      return i18n.t('indicador.banco.situacoes.vazio');
  }
}

function BancoCor(value: string, prefix = '') {
  switch (value) {
    case 'rejeitado':
      return `${prefix}danger`;
    case 'pendente':
      return `${prefix}warning`;
    case 'verificado':
      return `${prefix}success`;
    default:
      return 'light';
  }
}

function BancoBadge(value: string, prefix = '') {
  switch (value) {
    case 'rejeitado':
      return `${prefix}danger`;
    case 'pendente':
      return `${prefix}warning`;
    case 'verificado':
      return `${prefix}success`;
    default:
      return `${prefix}soft-secondary`;
  }
}

Vue.filter('BancoSituacao', BancoSituacao);
Vue.filter('BancoCor', BancoCor);
Vue.filter('BancoBadge', BancoBadge);
