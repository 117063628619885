















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class FiltroMultiEnum extends Vue {
  @Prop() public readonly data!: IFiltroField;
  public filtroSelecionado = [];

  get opcoes(): IFiltroEnum[] {
    return this.data.enum as IFiltroEnum[];
  }

  input(value: string[]): void {
    const filtro: IFiltroValues = {
      field: this.data.id,
      operator: 'in',
      value: value,
    };
    this.$emit('input', filtro);
  }
}
